import React from "react"
import PropTypes from "prop-types"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import Markdown from "markdown-to-jsx"

import language from "@language"
import { preFilterMarkdown } from "@utilities/markdownUtils"

import CustomImage from "@components/common/CustomImage"
import CopyLinkButton from "@components/common/CopyLinkButton"

import DirectoryProfileCard from "../DirectoryProfileCard"
import "./style.scss"

const DirectoryProfileSection = props => {
  return (
    <div className="directory-profile-section">
      <div className="page-wrap">
        <div className="row">
          <div className="directory-profile-section__left col-md-6 offset-md-1">
            <CustomImage
              className="directory-profile-section__image responsive-image mobile-md"
              alt={(props.medicalStaff.acf.image || {}).altText}
              src={(props.medicalStaff.acf.image || {}).sourceUrl}
              width={400}
            />
            <HeadingText
              className="directory-profile-section__name"
              size="xl"
              color="primary"
              text={props.medicalStaff.title}
            />
            <BodyText
              className="directory-profile-section__title"
              size="lg"
              color="dark-gray"
              text={props.medicalStaff.acf.title}
            />
            <hr className="directory-profile-section__short-divider" />
            <div className="directory-profile-section__departments-locations">
              <BodyText
                size="lg"
                color="dark-gray"
                text={props.medicalStaff.acf.departments
                  .map(d => d.name)
                  .join(", ")}
              />
              <BodyText
                size="lg"
                color="dark-gray"
                text={props.medicalStaff.acf.locations
                  .map(d => d.title)
                  .join(", ")}
              />
            </div>
            <DirectoryProfileCard
              className="mobile-md"
              language={props.language}
              medicalStaff={props.medicalStaff}
            />
            <CopyLinkButton className="mlr-auto mt-30px mobile-md" />

            {props.medicalStaff.acf.about && (
              <div className="directory-profile-section__about">
                <HeadingText
                  className="directory-profile-section__about-title"
                  size="md"
                  color="dark"
                  text={language[props.language].biography}
                />
                <BodyText
                  color="dark-gray"
                  size="md2"
                  text={props.medicalStaff.acf.about}
                />
              </div>
            )}
            <div className="directory-profile-section__contact">
              <HeadingText
                className="directory-profile-section__contact-title"
                size="md"
                color="dark"
                text={language[props.language].contact}
              />
              <Markdown
                className="directory-profile-section__address"
                size="md"
                color="dark-gray"
                options={{
                  overrides: {
                    p: {
                      component: BodyText,
                      props: {
                        color: "dark-gray",
                        size: "md",
                      },
                    },
                    span: {
                      component: BodyText,
                      props: {
                        className: "career-profile-section__list-grid-item",
                        color: "dark-gray",
                        size: "lg",
                      },
                    },
                  },
                }}
              >
                {preFilterMarkdown(props.medicalStaff.acf.contactAddress)}
              </Markdown>
              <BodyText
                color="dark-gray"
                size="md"
                text={props.medicalStaff.acf.phone}
              />
              <BodyText
                color="dark-gray"
                size="md"
                text={props.medicalStaff.acf.contactEmail}
              />
            </div>
            {props.medicalStaff.acf.conditions &&
              props.medicalStaff.acf.conditions.length && (
                <div className="directory-profile-section__module">
                  <HeadingText
                    className="directory-profile-section__module-title"
                    size="md"
                    color="dark"
                    text={language[props.language].conditions_i_treat}
                  />
                  <ul className="directory-profile-section__list-grid">
                    {props.medicalStaff.acf.conditions.map((e, index) => {
                      return <BodyText
                        key={`conditions--${index}`}
                        element="li"
                        className="mb-20px directory-profile-section__list-grid-item"
                        color="dark-gray"
                        size="sm"
                        text={e.entry}
                        fontWeight="300"
                      />
                    })}
                  </ul>
                </div>
              )}
            {props.medicalStaff.acf.procedures &&
              props.medicalStaff.acf.procedures.length && (
                <div className="directory-profile-section__module">
                  <HeadingText
                    className="directory-profile-section__module-title"
                    size="md"
                    color="dark"
                    text={language[props.language].procedures_i_do}
                  />
                  <ul className="directory-profile-section__list-grid">
                    {/*
                      columns of 5
                    */}
                    {props.medicalStaff.acf.procedures.map((e, index) => {

                      return (
                        <BodyText
                          key={`procedures--${index}`}
                          className="mb-20px directory-profile-section__list-grid-item"
                          color="dark-gray"
                          size="sm"
                          element="li"
                          text={e.entry}
                          fontWeight="300"
                        />

                      )
                    })}
                  </ul>
                </div>
              )}
          </div>
          <div className="directory-profile-section__right col-md-4 offset-md-1">
            <CustomImage
              className="directory-profile-section__image responsive-image desktop-md"
              alt={(props.medicalStaff.acf.image || {}).altText}
              src={(props.medicalStaff.acf.image || {}).sourceUrl}
              width={400}
            />
            <DirectoryProfileCard
              className="desktop-md"
              language={props.language}
              medicalStaff={props.medicalStaff}
            />
            <CopyLinkButton className="mlr-auto mt-30px desktop-flex-md" />
          </div>
        </div>
      </div>
    </div>
  )
}

DirectoryProfileSection.propTypes = {
  medicalStaff: PropTypes.shape({
    title: PropTypes.string,
    acf: PropTypes.shape({
      about: PropTypes.string,
      certifications: PropTypes.string,
      conditions: PropTypes.string,
      contactAddress: PropTypes.string,
      contactEmail: PropTypes.string,
      education: PropTypes.string,
      phone: PropTypes.string,
      procedures: PropTypes.string,
      residencies: PropTypes.string,
      title: PropTypes.string,
      departments: PropTypes.array,
      locations: PropTypes.array,
      image: PropTypes.shape({
        altText: PropTypes.string,
        sourceUrl: PropTypes.string,
      }),
    }),
  }),
  language: PropTypes.string,
}
DirectoryProfileSection.defaultProps = {
  language: "es",
  medicalStaff: {
    acf: {},
  },
}

export default DirectoryProfileSection
