import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"

import Layout from "@components/layout"
import SEO from "@components/seo"
import HeadingText from "@components/common/HeadingText"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"
import DirectoryProfileSection from "@templates/directory/components/DirectoryProfileSection"
import LargeArrowButton from "@components/common/LargeArrowButton"

import language from "@language"

import "./show.scss"
import MedicalPersonnelLocation from "./components/MedicalPersonnelLocation"

const DirectoryShow = props => {
  const page =
    !!props.preview && !!props.preview.doctor
      ? props.preview.doctor.preview.node
      : props.data.allWpDoctor.edges[0].node
  const indexPage =
    props.pageContext.pages.find(
      p => p.acfPageMeta.pageTemplate === "directory"
    ) || {}

  return (
    <Layout>
      <SEO title={page.title} />
      <div className="directory-show page-fade-in">
        <SimpleBreadcrumbHero
          pageTitle={page.title}
          size="sm"
          breadcrumbLink2={{
            url: indexPage.uri,
            title: indexPage.acfPageMeta.metaTitle,
          }}
        />
        <DirectoryProfileSection medicalStaff={page} />
        <MedicalPersonnelLocation
          className="bg-light-gray"
          location={page.acf.locations.filter(l => l.acf.publishFinal)[0]}
        />
      </div>
      <div className="page-wrap directory-show__cta-section pt-80px pb-80px">
        <LargeArrowButton direction="left" uri={indexPage.uri}>
          <HeadingText
            className="directory-show__cta-text"
            size="xl"
            color="primary"
            text={language[props.language].back_to_directory}
          />
        </LargeArrowButton>
      </div>
    </Layout>
  )
}

DirectoryShow.propTypes = {
  data: PropTypes.object,
  language: PropTypes.string,
  pageContext: PropTypes.shape({
    pages: PropTypes.array,
  }),
}
DirectoryShow.defaultProps = {
  data: {
    allWpDoctor: {
      edges: [],
    },
  },
  language: "es",
  pageContext: {
    pages: [],
  },
}

export const query = graphql`
  query($slug: String) {
    allWpDoctor(filter: { slug: { eq: $slug, ne: null } }) {
      edges {
        node {
          uri
          title
          acf {
            about
            contactAddress
            contactEmail
            certifications {
              entry
            }
            education {
              entry
            }
            fellowships {
              entry
            }
            residencies {
              entry
            }
            conditions {
              entry
            }
            procedures {
              entry
            }
            phone
            title
            image {
              altText
              sourceUrl
            }
            departments {
              name
            }
            locations {
              ... on WpLocation {
                id
                title
                uri
                acf {
                  publishFinal
                  map {
                    latitude
                    longitude
                    city
                  }
                  locationImage {
                    altText
                    sourceUrl
                  }
                  type {
                    id
                    name
                    slug
                    description
                  }
                  email
                  city
                  state
                  streetAddress
                  stateAbbreviation
                  telephone
                  zipCode
                }
              }
            }
          }
        }
      }
    }
  }
`

const PREVIEW_QUERY = gql`
  query PreviewDirectoryShowQuery($id: ID!) {
    doctor(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          uri
          title
          acf {
            about
            contactAddress
            contactEmail
            certifications {
              entry
            }
            education {
              entry
            }
            fellowships {
              entry
            }
            residencies {
              entry
            }
            conditions {
              entry
            }
            procedures {
              entry
            }
            phone
            title
            image {
              altText
              sourceUrl
            }
            departments {
              name
            }
            locations {
              ... on Location {
                id
                title
                uri
                acf {
                  map {
                    latitude
                    longitude
                    city
                  }
                  locationImage {
                    altText
                    sourceUrl
                  }
                  type {
                    id
                    name
                    slug
                    description
                  }
                  email
                  city
                  state
                  streetAddress
                  stateAbbreviation
                  telephone
                  zipCode
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(DirectoryShow)
