import React from "react"
import PropTypes from "prop-types"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"

import language from "@language"
import classnames from "classnames"

import "./style.scss"

const DirectoryProfileCard = props => {
  return (
    <div className={classnames("directory-profile-card", props.className)}>
      <div className="directory-profile-card__head">
        <HeadingText
          className="directory-profile-card__title"
          color="primary"
          size="sm"
          text={props.medicalStaff.title}
        />
        <BodyText
          color="dark-gray"
          size="sm"
          text={props.medicalStaff.acf.title}
        />
      </div>
      <div className="directory-profile-card__content">
        {props.medicalStaff.acf.education &&
          props.medicalStaff.acf.education.length && (
            <div className="directory-profile-card__content-section">
              <BodyText
                color="primary"
                size="sm"
                text={language[props.language].education}
              />
              {props.medicalStaff.acf.education.map((e, index) => {
                return (
                  <BodyText
                    key={`education--${index}`}
                    className="mb-20px"
                    color="dark-gray"
                    size="sm"
                    text={e.entry}
                  />
                )
              })}
            </div>
          )}
        {props.medicalStaff.acf.residencies &&
          props.medicalStaff.acf.residencies.length && (
            <div className="directory-profile-card__content-section">
              <BodyText
                color="primary"
                size="sm"
                text={language[props.language].residencies}
              />
              {props.medicalStaff.acf.residencies.map((e, index) => {
                return (
                  <BodyText
                    key={`residencies--${index}`}
                    className="mb-20px"
                    color="dark-gray"
                    size="sm"
                    text={e.entry}
                  />
                )
              })}
            </div>
          )}
        {props.medicalStaff.acf.fellowships &&
          props.medicalStaff.acf.fellowships.length && (
            <div className="directory-profile-card__content-section">
              <BodyText
                color="primary"
                size="sm"
                text={language[props.language].fellowships}
              />
              {props.medicalStaff.acf.fellowships.map((e, index) => {
                return (
                  <BodyText
                    key={`fellowships--${index}`}
                    className="mb-20px"
                    color="dark-gray"
                    size="sm"
                    text={e.entry}
                  />
                )
              })}
            </div>
          )}
        {props.medicalStaff.acf.certifications &&
          props.medicalStaff.acf.certifications.length && (
            <div className="directory-profile-card__content-section">
              <BodyText
                color="primary"
                size="sm"
                text={language[props.language].certifications}
              />
              {props.medicalStaff.acf.certifications.map((e, index) => {
                return (
                  <BodyText
                    key={`certifications--${index}`}
                    className="mb-20px"
                    color="dark-gray"
                    size="sm"
                    text={e.entry}
                  />
                )
              })}
            </div>
          )}
      </div>
    </div>
  )
}

DirectoryProfileCard.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
  medicalStaff: PropTypes.shape({
    title: PropTypes.string,
    acf: PropTypes.shape({
      certifications: PropTypes.array,
      education: PropTypes.array,
      fellowships: PropTypes.array,
      residencies: PropTypes.array,
      title: PropTypes.string,
    }),
  }),
}
DirectoryProfileCard.defaultProps = {
  medicalStaff: {
    acf: {},
  },
  language: "es",
}

export default DirectoryProfileCard
