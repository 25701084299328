import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import gql from "graphql-tag"

import Layout from "@components/layout"
import SEO from "@components/seo"

import language from "@language"

import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import CopyLinkButton from "@components/common/CopyLinkButton"
import ArticleMarkdownContent from "@components/common/ArticleMarkdownContent"

import ArticleNextPosts from "./components/ArticleNextPosts"
import { shortDateFormat } from "@utilities/copyUtils"
import PhotoCarousel from "../../components/common/PhotoCarousel"
import CustomImage from "@components/common/CustomImage"

import withPreview from "@components/withPreview"

import "./show.scss"

const NewsShow = props => {
  const page =
    !!props.preview && !!props.preview.newsPost
      ? props.preview.newsPost.preview.node // use preview data
      : props.data.allWpNewsPost.edges[0].node // use gatsby data
  const nextPost = props.pageContext.nextPost
  const prevPost = props.pageContext.prevPost
  const indexPage =
    props.pageContext.pages.find(p => p.acfPageMeta.pageTemplate === "news") ||
    {}

  return (
    <Layout>
      <div className="news-show page-fade-in">
        <SEO title={page.title} />

        {/*
        <ArticleShowHero
          indexPageUri={indexPage.uri}
          indexPageTitle={indexPage.acfPageMeta.metaTitle}
          pageTitle={page.title}
          image={page.acf.metaImage}
        />
        */}
        <SimpleBreadcrumbHero
          pageTitle={page.title}
          image={page.acf.metaImage}
          overlayType="full"
        />
        <div className="news-show__meta page-wrap pt-60px">
          <div className="news-show__meta-text">
            {page.acf.category && (
              <HeadingText
                className={`news-show__category ${page.acf.category.acfCategory.color}-text mb-10px mb-md-30px`}
                size="xxxs"
                text={page.acf.category.name}
                element="p"
              />
            )}
            <HeadingText
              className="news-show__title mb-40px mb-md-80px"
              color="primary"
              size="xxl"
              text={page.title}
            />
            <ArticleMarkdownContent
              className="desktop-lg news-show__content-spacing"
              content={page.acf.introductionContent}
            />
          </div>
          <div className="news-show__meta-right">
            <div className="news-show__meta-card">
              <div className="news-show__meta-section mb-20px">
                <BodyText
                  color="primary"
                  size="sm"
                  text={`${language[props.language].author}:`}
                />
                <BodyText
                  color="dark-gray"
                  size="sm"
                  text={page.acf.authorName}
                />
              </div>
              <div className="news-show__meta-section mb-20px">
                <BodyText
                  color="primary"
                  size="sm"
                  text={`${language[props.language].date}:`}
                />
                <BodyText
                  color="dark-gray"
                  className="text-capitalize"
                  size="sm"
                  text={shortDateFormat(props.language, page.acf.displayDate)}
                />
              </div>
              <div className="news-show__meta-section mb-20px">
                <BodyText
                  color="primary"
                  size="sm"
                  text={`${language[props.language].archive}:`}
                />
                <BodyText
                  color="dark-gray"
                  size="sm"
                  text={page.acf.category.name}
                />
              </div>
            </div>
            <CopyLinkButton
              className="news-show__copy-link mt-30px"
              language={props.language}
            />
          </div>

          <ArticleMarkdownContent
            className="mobile-lg news-show__content-spacing"
            content={page.acf.introductionContent}
          />
        </div>
        <div className="news-show__content">
          {page.acf.content &&
            page.acf.content.map((module, index) => {
              if (
                module.fieldGroupName === "NewsPost_Acf_Content_Article" &&
                !!module.content
              ) {
                return (
                  <div
                    key={`news-show__content--${index}`}
                    className="page-wrap"
                  >
                    <ArticleMarkdownContent
                      className="news-show__content-spacing news-show__text"
                      content={module.content}
                    />
                  </div>
                )
              } else if (
                module.fieldGroupName === "NewsPost_Acf_Content_ImageCarousel"
              ) {
                return (
                  <PhotoCarousel
                    key={`news-show__content--${index}`}
                    className="news-show__content-spacing news-show__carousel"
                    slides={module.images.map(image => (
                      <CustomImage
                        className="news-show__carousel-image"
                        src={image.sourceUrl}
                        alt={image.altText}
                      />
                    ))}
                  />
                )
              } else {
                return null
              }
            })}
        </div>
        <ArticleNextPosts
          prevPost={prevPost}
          nextPost={nextPost}
          language={props.language}
        />
      </div>
      {/* <pre>{JSON.stringify(page, null, 2)}</pre> */}
    </Layout>
  )
}

NewsShow.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    pages: PropTypes.array,
    directoryUri: PropTypes.string,
  }),
  language: PropTypes.string,
}
NewsShow.defaultProps = {
  data: {
    allWpNewsPost: {
      edges: [],
    },
  },
  language: "es",
  pageContext: {
    pages: [],
  },
}

export const query = graphql`
  query NewsPostQuery($slug: String) {
    allWpNewsPost(filter: { slug: { eq: $slug, ne: null } }) {
      edges {
        node {
          uri
          title
          acf {
            authorName
            introductionContent
            content {
              ... on WpNewsPost_Acf_Content_Article {
                content
                fieldGroupName
              }
              ... on WpNewsPost_Acf_Content_ImageCarousel {
                fieldGroupName
                images {
                  altText
                  sourceUrl
                }
              }
            }
            displayDate
            metaDescription
            metaImage {
              sourceUrl
              altText
            }
            category {
              id
              name
              acfCategory {
                color
              }
            }
          }
        }
      }
    }
  }
`

// Sorry, have to manually reconstruct the preview queries in the Graphiql tool on wordpress...
const PREVIEW_QUERY = gql`
  query BlogPreviewQuery($id: ID!) {
    newsPost(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          uri
          title
          acf {
            authorName
            introductionContent
            displayDate
            metaDescription
            metaImage {
              sourceUrl
              altText
            }
            category {
              id
              name
              acfCategory {
                color
              }
            }
            content {
              ... on NewsPost_Acf_Content_Article {
                content
                fieldGroupName
              }
              ... on NewsPost_Acf_Content_ImageCarousel {
                fieldGroupName
                images {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(NewsShow)
