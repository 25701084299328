import React from "react"
import PropTypes from "prop-types"

import LargeArrowButton from "@components/common/LargeArrowButton"

import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"

import language from "@language"

import "./style.scss"

const ArticleNextPosts = props => {
  return (
    <div className="article-next-posts">
      <div className="page-wrap article-next-posts__wrapper">
        <div className="article-next-posts__prev">
          {props.prevPost && (
            <LargeArrowButton uri={props.prevPost.uri} direction="left">
              <div className="article-next-posts__text">
                <BodyText
                  className="mb-10px"
                  color="dark"
                  size="md"
                  text={language[props.language].previous_news}
                />
                <HeadingText
                  color="primary"
                  size="sm"
                  text={props.prevPost.title}
                />
              </div>
            </LargeArrowButton>
          )}
        </div>
        <div className="article-next-posts__next">
          {props.nextPost && (
            <LargeArrowButton uri={props.nextPost.uri} direction="right">
              <div className="article-next-posts__text">
                <BodyText
                  className="mb-10px"
                  color="dark"
                  size="md"
                  text={language[props.language].next_news}
                />
                <HeadingText
                  color="primary"
                  size="sm"
                  text={props.nextPost.title}
                />
              </div>
            </LargeArrowButton>
          )}
        </div>
      </div>
    </div>
  )
}

ArticleNextPosts.propTypes = {
  language: PropTypes.string,
}
ArticleNextPosts.defaultProps = {
  language: "es",
}

export default ArticleNextPosts
