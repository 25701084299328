import React from "react"
import PropTypes from "prop-types"

import PageCarousel from "@components/PageCarousel"
import classnames from "classnames"

import "./style.scss"

const PhotoCarousel = props => {
  return (
    <div className={classnames("photo-carousel", props.className)}>
      <div className="photo-carousel__wrapper page-wrap">
        <PageCarousel
          align={props.align}
          childKeyIdentifier="photo-carousel"
          slides={props.slides.map(slide => (
            <div className="photo-carousel__image">{slide}</div>
          ))}
        />
      </div>
    </div>
  )
}

PhotoCarousel.propTypes = {
  align: PropTypes.oneOf(["start", "center"]),
  className: PropTypes.string,
  slides: PropTypes.array,
}
PhotoCarousel.defaultProps = {
  slides: [],
}

export default PhotoCarousel
