import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import queryString from "query-string"
import LocationShow from "@templates/locations/show"
import DirectoryShow from "@templates/directory/show"
import CareerShow from "@templates/careers/show"
import NewsShow from "@templates/news/show"

import language from "@language"
import HeadingText from "@components/common/HeadingText"

import "./404style.scss"

// !!!! CANT PREVIEW DRAFTS DUE TO AUTHENTICATION - can only preview published posts
// https://github.com/wp-graphql/wp-graphql/issues/325#issuecomment-572694522

const NotFoundRender = props => {
  const browser = typeof window !== "undefined" && window
  // https://github.com/gatsbyjs/gatsby/issues/18425
  // to fix 404 flashing error
  return (
    browser && (
      <Layout className="page-404">
        <SEO title="404: Not found" />
        <div>
          <div className="hero page-wrap">
            <HeadingText
              className="mt-40px"
              text={language[props.language].page_not_found}
            />
          </div>
        </div>
      </Layout>
    )
  )
}

const NotFoundPage = props => {
  const query = useStaticQuery(graphql`
    query Pages404Query {
      allWpPage {
        edges {
          node {
            uri
            acfPageMeta {
              pageTemplate
              metaTitle
            }
          }
        }
      }
    }
  `)

  const parsed = queryString.parse(props.location.search)
  const { nonce, preview, id, post_type } = parsed
  if (preview && id && nonce && post_type) {
    // these are set in the CPT UI under the post type "slug"
    switch (post_type) {
      case "locations":
        return (
          <LocationShow
            pageContext={{ pages: query.allWpPage.edges.map(e => e.node) }}
            location={props.location}
          />
        )
      case "directorio":
        return (
          <DirectoryShow
            pageContext={{ pages: query.allWpPage.edges.map(e => e.node) }}
            location={props.location}
          />
        )
      case "careers":
        return (
          <CareerShow
            pageContext={{ pages: query.allWpPage.edges.map(e => e.node) }}
            location={props.location}
          />
        )
      case "news":
        return (
          <NewsShow
            pageContext={{ pages: query.allWpPage.edges.map(e => e.node) }}
            location={props.location}
          />
        )
      default:
        return NotFoundRender(props)
    }
  } else {
    return NotFoundRender(props)
  }
  // if it's a preview page for an unpublished post, render the template!
}

NotFoundPage.propTypes = {
  language: PropTypes.string,
}

NotFoundPage.defaultProps = {
  language: "es",
}

export default NotFoundPage
