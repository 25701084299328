import React from "react"
import PropTypes from "prop-types"
import LocationMap from "@components/common/LocationMap"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import BrandButton from "@components/common/BrandButton"
import { cityStateZip } from "@utilities/copyUtils"

import language from "@language"
import classnames from "classnames"

import "./style.scss"

const MedicalPersonnelLocation = props => {
  return (
    <div className={classnames("medical-personnel-location", props.className)}>
      <div className="page-wrap">
        <div className="row">
          <div className="medical-personnel-location__content col-12 col-md-6">
            <HeadingText
              size="md"
              className="mb-60px"
              text={language[props.language].centers_where_i_work}
            />
            <HeadingText
              size="xl2"
              color="primary-dark"
              text={"Doctors' Center Hospital"}
            />
            <HeadingText
              size="xl2"
              color="primary"
              text={props.location.title}
              className="mb-20px"
            />
            <div className="medical-personnel-location__address mb-20px">
              {props.location.acf.streetAddress && (
                <BodyText color="dark-gray" size="md2">
                  {props.location.acf.streetAddress}
                </BodyText>
              )}
              <BodyText
                color="dark-gray"
                size="sm"
                text={cityStateZip(
                  props.location.acf.city,
                  props.location.acf.stateAbbreviation,
                  props.location.acf.zipCode
                )}
              />
            </div>
            <div className="medical-personnel-location__phone mb-60px">
              {props.location.acf.email && (
                <BodyText color="dark-gray" size="md2">
                  {props.location.acf.email}
                </BodyText>
              )}
              {props.location.acf.telephone && (
                <BodyText color="dark-gray" size="md2">
                  {props.location.acf.telephone}
                </BodyText>
              )}
            </div>
            <BrandButton
              className=""
              color="secondary"
              href={props.location.uri}
              text={language[props.language].learn_more}
            />
          </div>
          <div className="medical-personnel-location__map-container col-12 col-md-6">
            <LocationMap
              className="medical-personnel-location__map"
              locations={[props.location]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

MedicalPersonnelLocation.propTypes = {
  location: PropTypes.object,
}
MedicalPersonnelLocation.defaultProps = {
  location: {
    acf: {},
  },
  language: "es",
}

export default MedicalPersonnelLocation
